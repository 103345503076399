import './App.css';

import { Careers } from './pages/Careers';
export const DISCORD_URL = "https://discord.gg/aPQfnNkxGC";

function App() {  
  return (
    <Careers />
  );
}

export default App;
