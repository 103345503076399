export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export enum Architecture {
  LLAMA = "llama",
  PYTHIA = "pythia",
  GPT_NEO_X  = "gpt_neo_x",
  GPT_J = "gpt_j",
  MPT = "mpt",
  REPLIT = "replit",
  STARCODER = "starcoder",
  FALCON = "falcon",
  UNKNOWN = "?",
}

  
export const Badge = ({label, value, classes, valueMedium, hideLabel} : {label?: string, value: string, classes: string, valueMedium?:boolean, hideLabel?:boolean}) => {
  return (
    <div className={classNames("px-2 whitespace-nowrap py-0.5 rounded flex flex-row w-fit", "space-x-1", classes)}>
      {!hideLabel && label && <p className="opacity-60">{label}</p> }
      {value && <p className={(valueMedium ?? false) ? "font-medium" : ""}>{value}</p> }
    </div>
  );
}

export const ArchBadgeFalcon = "bg-emerald-400/5 text-emerald-400 border border-emerald-400/100 dark:bg-emerald-400/5 dark:text-emerald-400 dark:border-emerald-400/100";

export const badgeForArchitecture = (architecture: Architecture, hideLabel?: boolean) => {
  const sharedClasses = "py-0 text-xs";
  switch (architecture) {
    case Architecture.LLAMA:
      return <Badge hideLabel={hideLabel} label="Architecture" value="LLaMa" valueMedium classes={classNames(sharedClasses, "text-blue-400 border border-blue-400/100 bg-[#0D111730]")} />;
    case Architecture.MPT:
      return <Badge hideLabel={hideLabel} label="Architecture" value="MPT" valueMedium classes={classNames(sharedClasses, "text-pink-400 border border-pink-400/100")} />;
    case Architecture.REPLIT:
      return <Badge hideLabel={hideLabel} label="Architecture" value="Replit" valueMedium classes={classNames(sharedClasses, "text-slate-400 border border-slate-400/100")} />;
    case Architecture.STARCODER:
      return <Badge hideLabel={hideLabel} label="Architecture" value="StarCoder" valueMedium classes={classNames(sharedClasses, "text-yellow-400 border border-yellow-400/100")} />;
    case Architecture.GPT_NEO_X:
      return <Badge hideLabel={hideLabel} label="Architecture" value="GPT-Neo-X" valueMedium classes={classNames(sharedClasses, "text-purple-400 border border-purple-400/100")} />;
    case Architecture.FALCON:
      return <Badge hideLabel={hideLabel} label="Architecture" value="Falcon" valueMedium classes={classNames(sharedClasses, ArchBadgeFalcon)} />;
    default:
      return <></>;
  } 
}